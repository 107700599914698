import type { PropsWithChildren } from 'react';

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useSession } from '@/hooks/useSession';

function CheckAzureUrls({
	children
}: PropsWithChildren ) {
	const [ hasCheckedRedirects, setHasCheckedRedirects ] = useState( false );

	const { asPath, isReady, push } = useRouter();

	useEffect( () => {
		if ( !isReady || hasCheckedRedirects ) {
			return;
		}

		// there is a hash in the URL which means it's a redirect from Azure or a
		// change password request. Either way, we need to redirect somewhere.
		if ( asPath.includes( '#' ) ) {
			// check if it's a change password
			if ( asPath.includes( 'changepassword' ) ) {
				const splitPath = asPath.split( '/' );
				const changePasswordToken = splitPath[ splitPath.length - 1 ];

				push( `/changepassword/${ changePasswordToken }` );
			}

			// otherwise, it's coming from Azure
			const searchParams = new URLSearchParams( asPath.split( '#' )[ 1 ] );

			if ( searchParams.has( 'error' ) ) {
				// there is an error parameter, so don't redirect and react to the error
				// [TODO]: Display error messages
				console.log( 'error=', searchParams.get( 'error' ) );
				console.log( 'error_description=', searchParams.get( 'error_description' ) );
			}

			// if ( searchParams.has( 'invite' ) ) {
			// 	console.log( 'invite flow, redirecting', searchParams.get( 'invite' ) );

			// 	// this is an invitation request from Azure and should be redirected to the activation flow
			// 	push( `/user-activation/${ searchParams.get( 'invite' ) }` );
			// }

			if ( searchParams.has( 'state' ) && searchParams.has( 'id_token' ) ) {
				// these items should be handled by MSAL callback and would happen on the /auth/signin route
				push( `/auth/signin?authStateToken=${ searchParams.get( 'state' ) }&authIdToken=${ searchParams.get( 'id_token' ) }` );
			}

			return;
		}

		setHasCheckedRedirects( true );
	}, [ asPath, isReady, hasCheckedRedirects, push, setHasCheckedRedirects ] );

	if ( !hasCheckedRedirects ) {
		return null;
	}

	return <>{ children }</>;
}

function CheckLoginStatus() {
	const [ hasCheckedSession, setHasCheckedSession ] = useState( false );

	const { isLoading: isSessionLoading, session } = useSession();
	const { isReady, push } = useRouter();

	useEffect( () => {
		if ( hasCheckedSession || !isReady || isSessionLoading ) {
			return;
		}

		if ( session ) {
			push( '/dashboard' );
		} else {
			push( '/auth/signin' );
		}

		setHasCheckedSession( true );
	}, [ hasCheckedSession, isReady, isSessionLoading, push, session, setHasCheckedSession ] );

	return <></>;
}

export default function StartRouting() {
	return (
		<CheckAzureUrls>
			<CheckLoginStatus />
		</CheckAzureUrls>
	);
}
